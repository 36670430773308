<template>
  <div>
    <v-dialog
      v-model="dialog"
      max-width="480"
      max-height="400"
      persistent
      :fullscreen="$vuetify.breakpoint.smAndDown"
    >
      <v-card class="overflow-hidden">
        <v-row class="ma-0 ml-4">
          <span
            class="col-10 px-0 grey--text text--darken-4 text-h6 text-truncate"
            >{{ $t("remarkTicket") }}</span
          >
          <v-btn
            class="col-2 my-auto"
            aria-label="cross"
            icon
            @click="closeDialog()"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-row>
        <v-row no-gutters>
          <v-divider></v-divider>
        </v-row>
        <v-row no-gutters class="mx-4 mt-4" justify="center">
          <v-text-field
            v-model="contact"
            outlined
            dense
            required
            :hide-details="contactErrors.length === 0"
            :error-messages="contactErrors"
            @input="$v.contact.$touch()"
            @blur="$v.contact.$touch()"
            ><template v-slot:label>
              <span class="red--text"> * </span>{{ $t("username") }}
            </template></v-text-field
          >
        </v-row>
        <v-row no-gutters class="mx-4 mt-4">
          <span
            class="font-weight-medium grey--text text--darken-4 text-subtitle-2"
            ><span class="red--text"> * </span>{{ $t("facilityTypes") }}
          </span>
        </v-row>
        <div class="mx-6 mb-6">
          <v-row no-gutters>
            <v-checkbox v-model="facilityTypes" hide-details value="ELECTRIC">
              <template v-slot:label>
                <div
                  class="font-weight-regular grey--text text--darken-4 text-subtitle-2"
                >
                  {{ $t("Electric") }}
                </div>
              </template>
            </v-checkbox>
          </v-row>
          <v-row no-gutters>
            <v-checkbox v-model="facilityTypes" hide-details value="GAS/OIL">
              <template v-slot:label>
                <div
                  class="font-weight-regular grey--text text--darken-4 text-subtitle-2"
                >
                  {{ $t("Gas/Oil") }}
                </div>
              </template></v-checkbox
            >
          </v-row>
          <v-row no-gutters>
            <v-checkbox
              v-model="facilityTypes"
              hide-details
              value="COMMUNICATION"
            >
              <template v-slot:label>
                <div
                  class="font-weight-regular grey--text text--darken-4 text-subtitle-2"
                >
                  {{ $t("Communications") }}
                </div>
              </template>
            </v-checkbox>
          </v-row>
          <v-row no-gutters>
            <v-checkbox v-model="facilityTypes" hide-details value="WATER">
              <template v-slot:label>
                <div
                  class="font-weight-regular grey--text text--darken-4 text-subtitle-2"
                >
                  {{ $t("Water") }}
                </div>
              </template>
            </v-checkbox>
          </v-row>
          <v-row no-gutters>
            <v-checkbox v-model="facilityTypes" hide-details value="IRRIGATION">
              <template v-slot:label>
                <div
                  class="font-weight-regular grey--text text--darken-4 text-subtitle-2"
                >
                  {{ $t("Irrigation") }}
                </div>
              </template>
            </v-checkbox>
          </v-row>
          <v-row no-gutters>
            <v-checkbox v-model="facilityTypes" hide-details value="SEWER">
              <template v-slot:label>
                <div
                  class="font-weight-regular grey--text text--darken-4 text-subtitle-2"
                >
                  {{ $t("Sewer") }}
                </div>
              </template>
            </v-checkbox>
          </v-row>
        </div>
        <v-row no-gutters class="mx-4 mt-2 mb-4" justify="center">
          <v-text-field
            v-model="reason"
            outlined
            dense
            required
            :hide-details="reasonErrors.length === 0"
            :error-messages="reasonErrors"
            @input="$v.reason.$touch()"
            @blur="$v.reason.$touch()"
          >
            <template v-slot:label>
              <span class="red--text"> * </span>{{ $t("reason") }}
            </template></v-text-field
          >
        </v-row>
        <v-row no-gutters class="mx-4 mt-2 mb-4" justify="center">
          <v-textarea
            v-model="comments"
            outlined
            dense
            required
            hide-details=""
            rows="2"
            :label="$t('comments')"
          ></v-textarea>
        </v-row>
        <v-row
          no-gutters
          class="mx-4 mt-2"
          :class="$vuetify.breakpoint.smAndDown ? 'mb-16' : ''"
          justify="center"
        >
          <v-autocomplete
            v-model="selectedMembersToAdd"
            :items="membersToAddList"
            :menu-props="{ bottom: true, offsetY: true }"
            :label="$t('membersToAdd')"
            outlined
            dense
            required
            multiple
            item-text="mbcode_name"
            item-value="mbcode"
            @input="searchCountyMembers = null"
            :search-input.sync="searchCountyMembers"
          >
            <template v-slot:prepend-item>
              <v-list-item>
                <v-text-field
                  v-model="searchCountyMembers"
                  prepend-inner-icon="mdi-magnify"
                  :label="$t('searchMembersToAdd')"
                  single-line
                  hide-details
                  flat
                  dense
                  variant="underlined"
                  clear-icon="mdi-close-circle"
                  clearable
                >
                </v-text-field>
              </v-list-item>
            </template>
            <template v-slot:selection="{ attrs, item, parent, selected }">
              <v-chip
                size="small"
                density="compact"
                v-bind="attrs"
                :input-value="selected"
              >
                <span class="pr-2 chip-content">
                  {{ item.mbcode_name }}
                </span>
                <v-icon small @click="parent.selectItem(item)">
                  mdi-close-circle
                </v-icon>
              </v-chip>
            </template>
          </v-autocomplete>
        </v-row>
        <v-row class="mb-2" v-if="!$vuetify.breakpoint.smAndDown">
          <v-divider></v-divider>
        </v-row>
        <v-card-actions v-if="!$vuetify.breakpoint.smAndDown">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            outlined
            class="text-none rounded-lg elevation-0"
            depressed
            width="90"
            @click="closeDialog"
          >
            {{ $t("cancel") }}
          </v-btn>

          <v-btn
            class="primary text-none rounded-lg elevation-0"
            width="90"
            depressed
            @click="remarkTicketSubmit"
            :disabled="
              $v.$invalid || facilityTypes.length === 0 || submitButtonClicked
            "
          >
            {{ $t("submit") }}
          </v-btn>
        </v-card-actions>
        <v-bottom-navigation
          v-if="$vuetify.breakpoint.smAndDown"
          fixed
          horizontal
          height="70"
          class="px-2 py-4"
          ><v-row justify="space-between" no-gutters>
            <v-btn
              color="primary"
              outlined
              class="mr-2 text-none rounded-lg elevation-0"
              width="50%"
              @click="closeDialog"
            >
              {{ $t("cancel") }}
            </v-btn>
            <v-btn
              color="primary"
              class="text-none font-weight-regular grey--text rounded-lg elevation-0"
              width="50%"
              depressed
              :disabled="
                $v.$invalid || facilityTypes.length === 0 || submitButtonClicked
              "
              @click="remarkTicketSubmit"
              ><span class="white--text text-none">{{ $t("submit") }}</span>
            </v-btn>
          </v-row></v-bottom-navigation
        >
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import moment from "moment";
import { required, maxLength, minLength } from "vuelidate/lib/validators";
export default {
  mixins: [validationMixin],
  validations() {
    return {
      contact: { required, maxLength: maxLength(30), minLength: minLength(1) },
      reason: { required, minLength: minLength(1) },
    };
  },
  props: {
    dialog: {
      type: Boolean,
      default: () => false,
    },
    ticketData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      contact: "",
      selectedMembersToAdd: [],
      reason: "",
      comments: "",
      facilityTypes: [],
      searchCountyMembers: null,
      membersToAddList: [],
      submitButtonClicked: false,
    };
  },
  async created() {
    if (this.ticketData && this.ticketData.center) {
      await this.getMembersToAdd();
    }
  },
  watch: {
    async ticketData() {
      await this.getMembersToAdd();
    },
  },
  methods: {
    async remarkTicketSubmit() {
      this.submitButtonClicked = true;
      let remarkTicketPayload = {
        ticket_id: this.ticketData.ticket_id,
      };
      let apiData = {
        data: {
          newCaller: this.contact,
          reason: this.reason,
          facilityTypes: this.facilityTypes.join(","),
        },
      };
      if (this.reason && this.reason.length > 0) {
        apiData.data.reason = this.reason;
      }
      if (this.comments && this.comments.length > 0) {
        apiData.data.inHouseComments = this.comments;
      }
      if (this.selectedMembersToAdd && this.selectedMembersToAdd.length !== 0) {
        apiData.data.membersToAdd = this.selectedMembersToAdd.join(",");
      } else apiData.data.membersToAdd = "";
      apiData.ticket = this.ticketData.ticket;
      apiData.center = this.ticketData.centerData.center;
      remarkTicketPayload.apiData = apiData;

      let previousRevision = this.ticketData.revision;
      const updateTicketResp = await this.$store.dispatch(
        "revisionRemarkTicket",
        remarkTicketPayload
      );

      if (updateTicketResp.status === "error") {
        console.log("Error in Updating Remark Ticket Details");
        this.$emit("errorSubmitTicket", updateTicketResp);
      } else {
        this.$emit(
          "remarkTicketSuccess",
          this.ticketData.ticket,
          previousRevision
        );
      }
      this.closeDialog();
      this.submitButtonClicked = false;
    },
    async getMembersToAdd() {
      this.membersToAddList = [];
      let membersToAddArray = [];
      if (this.ticketData && this.ticketData.centerData) {
        const membersToAddData = await this.$store.dispatch(
          "getMembersDetailsInCounty",
          {
            state: this.ticketData.state,
            county: this.ticketData.county,
            url: this.ticketData.centerData.url,
            center: this.ticketData.centerData.center,
          }
        );
        if (membersToAddData.status !== "error") {
          membersToAddArray = membersToAddData.map(({ code, name }) => {
            return {
              mbcode: code,
              name,
              mbcode_name: `${code} - ${name}`,
            };
          });
          this.membersToAddList = membersToAddArray;
          await this.$store.commit("setMembersToAdd", this.membersToAddList);
        }
      }
    },
    closeDialog() {
      this.contact = "";
      this.searchCountyMembers = null;
      this.comments = "";
      this.reason = "";
      this.selectedMembersToAdd = [];
      this.submitButtonClicked = false;
      this.$emit("closeRemarkDialog");
    },
  },
  computed: {
    contactErrors() {
      const errors = [];
      if (!this.$v.contact.$dirty) return errors;
      !this.$v.contact.required && errors.push(this.$t("Username is required"));
      !this.$v.contact.minLength && errors.push(this.$t("contactMinLength"));
      !this.$v.contact.maxLength && errors.push(this.$t("contactMaxLength"));
      return errors;
    },
    reasonErrors() {
      const errors = [];
      if (!this.$v.reason.$dirty) return errors;
      !this.$v.reason.minLength &&
        errors.push(this.$t("Reason must be at least 1 characters long"));
      !this.$v.reason.required && errors.push(this.$t("reasonRequired"));
      return errors;
    },
  },
};
</script>

<style lang="scss" scoped>
.v-select.v-input--dense .v-chip {
  margin: 8px 4px 0 4px;
}
.chip-content {
  max-width: 95%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.v-chip__close {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
</style>
